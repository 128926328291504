<template>
    <b-row>
        <b-col sm="12">
            <b-card header-tag="header" footer-tag="footer">
                <b-row>
                    <b-col>
                        <h4 id="traffic" class="card-title mb-0">Configuración - Contabilidad</h4>
                        <div class="small text-muted">Configuraciones generales</div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card header-tag="header" footer-tag="footer" no-body>
                <b-row>
                    <b-col>      
                        <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
                            <b-tab title="Mis Empresas" :active="tabs.business" @click="setActiveTab('business')">
                                <tab-business></tab-business>
                            </b-tab>
                            <b-tab title="Cuentas Contables Por Defecto" :active="tabs.accountingSettings" @click="setActiveTab('accountingSettings')">
                                <tab-accountingSettings></tab-accountingSettings>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import serviceAPI from './services'
    import Error from '@/handler/error'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper' 
    import Storage from '@/handler/storageLocal'
    import TabBusiness from '@/components/inc/myBusiness/business'
    import TabAccountingSettings from './settings/accountingSettings'

    export default {
        components: {
            TabBusiness,
            TabAccountingSettings
        },
        data: () => {
            return {      
                access: {
                    module_id: Modules.CONTABILIDAD,
                    profile_id: Profiles.PERSONAL,
                    view_reference: 'settings',
                    elements: {}
                },
                tabs: {
                    business: false,
                    accountingSettings: false,
                }
            }
        },
        created () {     
            /* Configurar permisos de vistas y elementos */
            Helper.hasAccessView(this.access)
            /* Fin configuracion */
        }, 
        mounted () {
            this.restoreStatusTabsStorage()
        },
        methods: {
            linkClass(idx) {
                if (this.tabIndex === idx) {
                    return ['bg-primary', 'text-light']
                } else {
                    return ['bg-light', 'text-info']
                }
            },

            //TABS
            disableAllTabs() {
                this.tabs.business = false
                this.tabs.accountingSettings = false
            },
            setActiveTab(value) {
                this.disableAllTabs()

                switch (value) {
                    case 'business':
                        this.tabs.business = true
                        break;        

                    case 'accountingSettings':
                        this.tabs.accountingSettings = true
                        break;        

                    default:
                        this.tabs.business = true
                        break;
                }

                this.saveStatusTabsStorage()
            },

            // FILTROS SAVE
            saveStatusTabsStorage() {
                Storage.setValue('tabs_view_accounting', JSON.stringify(this.tabs))
            },
            restoreStatusTabsStorage() {
                if(Storage.getValue('tabs_view_accounting')) {
                this.tabs = JSON.parse(Storage.getValue('tabs_view_accounting'))
                }         
            },
        } 
    }
</script>